.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/***********************/
.header{
  background-color: #ffffff;
  border-bottom: solid 2.5px rgba(0,0,0,0.2);
}

.title{
  font-size: 2em;
  color: #7b25ba;
  font-family: 'Avenir';
  font-weight: 800;
}

.CodeView-container, .ChartView-container, .LinterView-container{
  padding: 20px;
}

.CodeView-container{
  border-right: solid 2px rgba(0,0,0,0.2);
}

.LinterView-container{
  border-top: solid 2px rgba(0,0,0,0.2);
  height: 50%;
}

.pjBtn:hover {
  text-decoration: underline;
  /* color: #7b25ba !important; */
}